<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
export default {
    name: "LostPassword",
    mixins: [validationMixin],
    methods: {
        validateState(name) {
            return this.$v[name].$dirty? (!this.$v[name].$invalid? null:false) :null
            // const { $dirty, $error } = this.$v[name];
            // return $dirty ? !$error : null;
        },
        handleSubmit() {
            // stop here if form is invalid
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.$refs.submitButton.disabled=true;
            this.loading = true;

            try {
                this.$http
                    .post("/forgot-password-link", {
                        email: this.email 
                    })
                    .then(response => {
                        console.log('@@response', response, this.loading);
                        if (
                            response.data &&
                            response.data.status &&
                            response.data.status == "success"
                        ) {
                            // alert("Correct Username & Password!");
                            this.$notify({
                                title: "SUCCESS!",
                                text:
                                    "We have sent you an email. Please follow instructions from there.",
                                type: "success"
                            });
                        } else if (
                            response.data &&
                            response.data.status &&
                            response.data.status == "fail"
                        ) {
                            this.$notify({
                                title: "SUCCESS!",
                                text:
                                    "We have sent you an email. Please follow instructions from there.",
                                type: "success"
                            });
                        }
                        this.$refs.submitButton.disabled=false;
                        this.loading = false;
                    })
                    .catch(function(error) {
                        this.loading = false;
                        console.warn(error);
                    })
            } catch (e) {
                console.warn(e);
            }
        }
    },
    validations: {
        email: { required, email }
    },
    data() {
        return {
            loading: false,
            email: ""
        };
    }
};
</script>
<template>
   <b-container class="page password-lost">
      <div class="content-wrap narrow">
         <h1 class="text-center">{{this.$t("password_reset.title")}}</h1>
         <p class="text-center">
            {{this.$t("password_reset.body")}}
         </p>
         <b-form @submit.stop.prevent="handleSubmit">
            <b-form-group>
               <b-form-input 
                  type="text"
                  placeholder
                  v-model="$v.email.$model"
                  :state="validateState('email')"
                  ></b-form-input>
               <b-form-invalid-feedback>This field requires a valid email id.</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group>
               <b-button variant="primary" type="submit" ref="submitButton" block>
                   <b-spinner v-if="loading" small label="Spinning"></b-spinner>
                   {{this.$t("forms.submit")}}
                </b-button>
            </b-form-group>
            <p  class="text-center m-0" >
               <router-link to="/login">{{this.$t("password_reset.login")}}</router-link>
            </p>
         </b-form>
      </div>
   </b-container>
</template>
